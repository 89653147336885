import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../components/button'

const CTA = ({ title, btn }) => {
  const CTAOuterContainer = styled.section`
    padding: 2.5em;
    text-align: center;

    h2 {
      margin-bottom: 0;
      color: #fff;
      @media (max-width: 992px) {
        margin-bottom: 0.5em;
        font-size: 1.5em;
      }
    }
  `

  const ContentContainer = styled.div`
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `

  return (
    <CTAOuterContainer className={'bg-color-primary'}>
      <div className="container">
        <ContentContainer>
          <h2>{title}</h2>
          <Button
            to={'/contact'}
            title={'Get Started with Scheduling a Free Consultation'}
            color={'dark'}
          >
            {btn}
          </Button>
        </ContentContainer>
      </div>
    </CTAOuterContainer>
  )
}

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
}

export default CTA
