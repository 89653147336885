import React, { Component} from 'react';
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styles from '../styles/variables.scss'
import ItemListing from "../components/item-listing"
import Card from "../components/card"
import styled from 'styled-components'
import ServiceHero from "../components/service-hero"
import Reputation from '../components/reputation'
import CTA from '../components/cta-bar'



class ServiceTemplate extends Component {
  render() {

    const pageData = this.props.data.contentfulService;

    const MainContentContainer = styled.div``

    const SidebarContainer = styled.div``

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) =>
        <Card key={index} sidebar={sidebar} />
      );

      return sideBarCards;
    }

    console.log(pageData.heroImage.fixed)

    return (
      <Layout>
        <SEO title={pageData.seoTitle} description={pageData.metaDescription} img={pageData.heroImage.fixed.src}/>
        <ServiceHero pageData={pageData}/>
        <div className="container brand-padding-y service-page">
          <div className="row">
            <MainContentContainer className={pageData.sidebar ? 'col-12 col-md-8 is-service-page' : 'col-12 is-service-page'}>
               {pageData && pageData.content && pageData.content.content ? (<div dangerouslySetInnerHTML={{ __html: pageData.content.content }}/>) : ''}
            </MainContentContainer>
            {pageData.sidebar ? <SidebarContainer className="col-12 col-md-4"><SidebarColumn/></SidebarContainer>: ''}
          </div>
        </div>
        <CTA title={"Start Your Remodel Today!"} btn={'Schedule a Free Consultation'}/>


      </Layout>
    )
  }
}


export default ServiceTemplate


export const pageQuery = graphql`
  query serviceQuery($slug: String!){
    contentfulService(slug: {eq: $slug}) {
      id
      serviceTitle
      seoTitle
      metaDescription
      slug
      excerpt
      content {
        content
      }
      heroImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 1200, height: 630) {
          width
          height
          src
          srcSet
          srcWebp
        }
      }
      callToAction
      sidebar{
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
    }
  }
`




